<template>
  <div
    class="pb-5 mb-2 jft-78"
    :class="currentTab == 'CandidateInbox' ? 'pt-3' : ''"
  >
    <LoadingOverlay v-if="isFetchingEmailConversation" />
    <div class="documentScrolling">
      <div class="scratch-pad">
        <CCard class="modal-primary">
          <CCardHeader class="modal-header d-block">
            <div
              class="card-header-title d-flex align-items-center justify-content-between"
              :style="{ height: currentTab == 'CandidateInbox' ? '40px' : '' }"
            >
              <div class="col-md-1 custom-checkbox">
                <input
                  v-if="getFilterOnly.length && currentTab == 'Inbox'"
                  type="checkbox"
                  v-model="allSelected"
                  @change="selectAll"
                />
                <CDropdown
                  placement="bottom-end"
                  :show.sync="isShowDrop"
                  v-if="getFilterOnly.length && currentTab == 'Inbox'"
                >
                  <template #toggler>
                    <span
                      class="material-icons dot-icon cursor-pointer ml-2"
                      style="font-size: 20px"
                    >
                      arrow_drop_down
                    </span>
                  </template>
                  <div class="dropdown-item-group">
                    <CLink
                      class="dropdown-item cursor-pointer"
                      @click="markAsRead(selected)"
                      >Mark as Read</CLink
                    >
                    <CLink
                      class="dropdown-item cursor-pointer"
                      @click="markAsUnRead(selected)"
                      >Mark as Unread</CLink
                    >
                  </div>
                </CDropdown>
              </div>
              <div class="col-11 pr-0">
                <div class="input-group float-right w-100">
                  <div class="custom-email-filter col-lg-2 pr-0">
                    <Select
                      name="appliedFilter"
                      class="border-rounded"
                      style="background-color: #b8b8b8"
                      :value="appliedFilter"
                      :options="
                        options && options['filters'] ? options['filters'] : []
                      "
                      :multiple="false"
                      :clearable="false"
                      @input="applyFilter"
                    />
                  </div>
                  <input
                    type="text"
                    class="form-control m-0 search"
                    :placeholder="`Filter by Email, Subject${
                      currentTab != 'CandidateInbox'
                        ? ', Job Id and Candidate Id'
                        : ''
                    }`"
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-secondary text-white"
                      type="button"
                      title="Search"
                      @click="searchTerm"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <div v-if="currentTab != 'CandidateInbox'">
                      <button
                        v-if="currentTab != 'Sent'"
                        class="btn btn-secondary text-white ml-1"
                        type="button"
                        title="Compose Email"
                        @click="composeEmail"
                      >
                        <i class="fa fa-paper-plane mr-1"></i>
                        <b v-if="composeBtn">Compose</b>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CCardHeader>
          <CCardBody
            class="scroll-area-lg p-0"
            v-if="emailConversationList.length"
          >
            <div class="to-do-content">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  v-for="(row, index) in emailConversationList"
                  :key="index"
                  :style="{
                    'background-color':
                      row.read == true || currentTab == 'Sent' ? '#f5f5f5' : '',
                  }"
                >
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper row">
                      <div class="widget-content-left col-md-1 col-lg-1">
                        <div class="custom-checkbox" color="primary">
                          <div v-if="currentTab != 'CandidateInbox'">
                            <input
                              v-if="currentTab != 'Sent'"
                              class="mt-2 ml-2"
                              type="checkbox"
                              :value="row.message_id"
                              v-model="selected"
                            />
                          </div>
                          <!-- <i
                              v-if="currentTab != 'Sent'"
                              :class="
                                row.read == true
                                  ? 'fas fa-envelope-open'
                                  : 'fas fa-envelope'
                              "
                              class="text-primary"
                            ></i>
                            <i
                              v-else
                              class="text-primary fas fa-envelope-open"
                            ></i> -->
                          <i
                            v-if="row.attachment[0]"
                            class="fa fa-paperclip icon"
                            style="font-size: 13px !important; color: #c8c8c8"
                            :class="{
                              'float-right mt-2': currentTab == 'Inbox',
                            }"
                          ></i>
                        </div>
                      </div>

                      <div class="widget-content-left col-md-5 col-lg-5 d-flex">
                        <div class="justify-content-start align-items-start">
                          <label
                            class="my-1 mx-3 cursor-pointer"
                            @click="openCandidateInfoModal(row.candidate_uid)"
                          >
                            <span>
                              <img
                                :src="profilePicture(row)"
                                class="rounded-circle"
                                alt="profile"
                                width="35"
                                @error="
                                  $event.target.src =
                                    '/img/user_default_pic.png'
                                "
                              />
                            </span>
                          </label>
                        </div>
                        <div class="widget">
                          <div class="widget-heading">
                            <label
                              v-if="currentTab == 'CandidateInbox'"
                              class="cursor-pointer cs-32 m-0"
                              title="Click here to view all email conversations"
                              v-html="
                                `${row.sender_name} (${
                                  row.organisation_name ||
                                  row.customer_name ||
                                  '--'
                                })`
                              "
                              @click="allEmailConversation(row)"
                            ></label>
                            <label
                              v-else
                              class="cursor-pointer cs-32 m-0"
                              title="Click here to view all email conversations"
                              v-html="row.email_id"
                              @click="allEmailConversation(row)"
                            ></label>
                          </div>
                          <div class="widget-subheading text-muted">
                            <p class="mb-0">
                              <small class="opacity-6 ml-1 mr-1">
                                <i class="fas fa-calendar" />
                                {{ row.load_datetime }}
                              </small>
                              <span v-if="currentTab != 'CandidateInbox'">
                                <i
                                  class="jobLink"
                                  v-if="row.job_id"
                                  @click="goToJobPage(row.job_id)"
                                >
                                  Job ID:{{ row.job_display_uid }}
                                </i>
                                <i
                                  class="jobLink fj-90"
                                  style="background-color: #fbe7e7"
                                  v-if="row.candidate_uid"
                                  @click="goToUserPage(row.candidate_uid)"
                                >
                                  UID:{{ row.candidate_display_uid }}
                                </i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="widget-content-left col-md-5 col-lg-5">
                        <div
                          class="font-weight-bolder cursor-pointer cs-32"
                          title="Click here to view subject conversations"
                          v-html="row.subject"
                          @click="subjectEmailConversation(row)"
                        ></div>
                        <div class="message">
                          <span
                            class="d-flex"
                            v-html="getBody(row.body)"
                          ></span>
                        </div>
                      </div>
                      <div
                        class="widget-content-left col-md-1 col-lg-1"
                        align="center"
                        v-if="messageThread(row).length > 1"
                      >
                        <div class="unread w-50">
                          <span>{{ messageThread(row).length }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </CCardBody>
          <CCardBody class="scroll-area-lg p-0" v-else>
            <div class="to-do-content">
              <ul class="list-group list-group-flush" colspan="4">
                <li class="my-5">
                  <div class="text-center my-5">
                    <h2>
                      {{ btnNothing }}
                      <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                    </h2>
                  </div>
                </li>
              </ul></div
          ></CCardBody>
        </CCard>
      </div>
    </div>
    <EmailModal
      v-if="emailModal.isShow"
      :isShowPopup="emailModal.isShow"
      :email_id="emailModal.email_id"
      :emailBox="emailModal.emailBox"
      :sender="emailModal.sender"
      :sender_name="emailModal.sender_name"
      :customer_user_id="emailModal.customer_user_id"
      :email_subject="emailModal.subject"
      :candidate_uid="emailModal.candidate_uid"
      :recruiter_message="emailModal.recruiter_message"
      @emailModalCallBack="ModalCallBack"
      @conversationModalCallBack="conversationModalCallBack"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      @modalCallBack="candidateInfoModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EmailModal from "@/containers/Communication/EmailModal";
import DeleteModal from "@/containers/Communication/DeleteModal";
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import Select from "@/components/reusable/Fields/Select.vue";

export default {
  props: {
    getBoundOnly: {
      type: Array,
      default: null,
    },
    currentTab: {
      type: String,
      default: null,
    },
  },
  components: {
    EmailModal,
    DeleteModal,
    CandidateInfoModal,
    Select,
  },
  data() {
    return {
      emailModal: {
        isShow: false,
        email_id: "",
        sender: "",
        customer_user_id: "",
        sender_name: "",
        subject: "",
        date_time: "",
        candidate_uid: "",
        emailBox: false,
        recruiter_message: false,
      },
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
      showDialog: {
        email: false,
      },
      filter: "",
      btnNothing: null,
      candidatePageUrl: "/candidate/",
      jobPageUrl: "/job-list-preview/",
      isShowDrop: false,
      isShowDropFilter: false,
      selected: [],
      allSelected: false,
      appliedFilter: { label: "All", code: null },
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
    };
  },
  watch: {
    selected() {
      if (this.selected.length == this.getFilterOnly.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "noMoreDataFromEmailList",
      "getNotificationEmailMessages",
      "getWindowWidth",
      "isFetchingEmailConversation",
    ]),
    options() {
      return {
        filters: [
          { label: "All", code: null },
          { label: "Read", code: "true" },
          { label: "Unread", code: "false" },
        ],
      };
    },
    getUniqConversation() {
      let array = [];
      if (this.currentTab == "CandidateInbox") {
        array = _.uniqBy(this.getBoundOnly, (v) =>
          [
            v.sender,
            v.subject.split(": ")[1] ? v.subject.split(": ")[1] : v.subject,
          ].join()
        );
      } else {
        array = _.uniqBy(this.getBoundOnly, (v) =>
          [v.email_id, v.sender, v.subject].join()
        );
      }

      return this.getBoundOnly.length ? array : [];
    },
    getFilterOnly() {
      return this.emailConversationList
        ? _.filter(this.emailConversationList, (message) => {
            if (this.currentTab == "CandidateInbox") {
              if (message.direction === "O") {
                return message;
              }
            } else {
              if (message.direction === "I") {
                return message;
              }
            }
          })
        : [];
    },
    emailConversationList() {
      this.getUniqConversation.length == 0
        ? (this.btnNothing = "No Data Found")
        : null;
      return this.getUniqConversation;
    },
    composeBtn() {
      if (this.getWindowWidth > 882) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["updateReadMessage"]),
    async selectAll() {
      if (this.allSelected) {
        const selected = this.emailConversationList.map((u) => u.message_id);
        this.selected = selected;
      } else {
        this.selected = [];
      }
    },
    subjectEmailConversation(data) {
      this.messageThread(data)?.forEach((e) => {
        if (e.read == false) {
          this.updateReadMessage({
            message_id: e.message_id,
            read: true,
            email: e.email_id,
          });
          e.read = true;
        }
      });
      this.emailModal = {
        isShow: true,
        emailBox: false,
        email_id: data.email_id,
        sender: data.sender,
        sender_name: data.sender_name,
        customer_user_id: data.customer_user_id,
        candidate_uid: data.candidate_uid,
        subject: data.subject,
        recruiter_message: true,
      };
    },
    ModalCallBack() {
      this.emailModal.isShow = false;
    },
    conversationModalCallBack(delete_id, subject) {
      this.deleteModal = {
        isShowPopup: true,
        delete_id: delete_id,
        subject: subject,
      };
      this.emailModal.isShow = false;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.showDialog.email = true;
      this.emailModal.isShow = true;
    },
    modalCallBack(columnKey) {
      this.showDialog[columnKey] = false;
    },
    markAsRead(data) {
      for (var i = 0; i < data.length; i++) {
        _.filter(this.getFilterOnly, (message) => {
          if (message.message_id === data[i]) {
            if (message.read != true) {
              message.read = true;
              this.updateReadMessage({
                message_id: data[i],
                read: true,
                email: this.email,
              });
            }
          }
        });
      }
      this.selected = [];
      this.isShowDrop = false;
    },
    markAsUnRead(data) {
      for (var i = 0; i < data.length; i++) {
        _.filter(this.getFilterOnly, (message) => {
          if (message.message_id === data[i]) {
            if (message.read == true) {
              message.read = false;
              this.updateReadMessage({
                message_id: data[i],
                read: false,
                email: this.email,
              });
            }
          }
        });
      }
      this.selected = [];
      this.isShowDrop = false;
    },
    allEmailConversation(data) {
      this.messageThread(data)?.forEach((e) => {
        if (e.read == false) {
          this.updateReadMessage({
            message_id: e.message_id,
            read: true,
            email: e.email_id,
          });
          e.read = true;
        }
      });
      this.emailModal = {
        isShow: true,
        emailBox: false,
        email_id: data.email_id,
        sender: data.sender,
        sender_name: data.sender_name,
        customer_user_id: data.customer_user_id,
        candidate_uid: data.candidate_uid,
        subject: data.subject,
        recruiter_message: false,
      };
    },
    goToJobPage(data) {
      this.$router.push(this.jobPageUrl + data);
    },
    goToUserPage(data) {
      this.$router.push(this.candidatePageUrl + data);
    },
    getBody(data) {
      String.prototype.allReplace = function (obj) {
        var retStr = this;
        for (var x in obj) {
          retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
        }
        return retStr;
      };

      data = data.allReplace({
        "<p>": '<p class="mb-0">',
        "<h1>": '<p class="mb-0">',
        "<h2>": '<p class="mb-0">',
        "<h3>": '<p class="mb-0">',
        "<h4>": '<p class="mb-0">',
        "<h5>": '<p class="mb-0">',
        "<h6>": '<p class="mb-0">',
        "</h1>": "</p>",
        "</h2>": "</p>",
        "</h3>": "</p>",
        "</h4>": "</p>",
        "</h5>": "</p>",
        "</h6>": "</p>",
        "<ol><li>": "",
        "<ul><li>": "",
        "<p><br></p>": "",
        "</li><li>": " ",
        "</li></ol>": " ",
        "</li></ul>": " ",
        "</p><p>": " ",
      });
      return data.length > 85 ? `${data.slice(0, 85)}...` : data;
    },
    searchTerm() {
      if (this.filter) this.$emit("emailGridCallBack", this.filter);
    },
    composeEmail() {
      this.emailModal = {
        isShow: true,
        emailBox: true,
        email_id: null,
        sender: null,
        sender_name: null,
        customer_user_id: null,
        candidate_uid: null,
        subject: null,
        recruiter_message: false,
      };
    },
    messageThread(value) {
      let msgCount = [];
      return this.getBoundOnly && this.getBoundOnly?.length
        ? _.filter(this.getBoundOnly, (message) => {
            if (
              this.currentTab == "CandidateInbox" &&
              message.direction == "O" &&
              message.sender == value.sender &&
              message.email_id == value.email_id &&
              message.subject == value.subject
            ) {
              return msgCount.push(message);
            } else if (
              this.currentTab == "Inbox" &&
              message.direction == "I" &&
              message.sender == value.sender &&
              message.subject == value.subject &&
              message.email_id == value.email_id
            ) {
              return msgCount.push(message);
            } else if (
              this.currentTab == "Sent" &&
              message.direction == "O" &&
              message.sender == value.sender &&
              message.email_id == value.email_id &&
              message.subject == value.subject
            ) {
              return msgCount.push(message);
            }
          })
        : [];
    },
    profilePicture(data) {
      return data?.user?.profile_picture
        ? data.user.profile_picture
        : "/img/user_default_pic.png";
    },
    openCandidateInfoModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    applyFilter(name, value) {
      this.appliedFilter = value;
      this.$emit("applyFilter", value.code);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: theme-color("primary");

.cs-32:hover {
  color: blue;
  text-decoration: underline;
}
.email-filter {
  color: #c8c9cc;
  position: absolute;
  right: 5px;
  top: 10px;
  font-size: 20px;
}

.custom-checkbox {
  input[type="checkbox"] {
    margin-top: -30px;
  }

  input[type="checkbox"] {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeSpeed;
    width: 14px;
    height: 14px;
    margin: 0 1px 0 0;
    display: block;
    float: left;
    position: relative;
    cursor: pointer;
    z-index: 999;

    &:after {
      content: "";
      vertical-align: middle;
      text-align: center;
      line-height: 13px;
      position: absolute;
      cursor: pointer;
      height: 19px;
      width: 19px;
      left: 0px;
      top: 0px;
      font-size: 10px;
      border: solid 2px #ccc;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
      background: #fff;
      border-radius: 3px;
    }

    &:hover:after {
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
    }

    &:checked:after {
      border-color: #767676;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAiUlEQVR4AbXStQECMQBA0V9iS+HW4LAmNW6zpMbd4dz9LvriIaKniOBp8QqKCvwya6rAp8PrAC906DvDKw2g7gDfrAWUORnhhpWKtYHilxlgkTjDb+pGFyhwNF/MnNSbjt6sB+S/zAx+aZIEBSDLwX57Zm8KmTdz3McpVfauN9w39HEpnK5ZJM8Ldd7hI/X/HugAAAAASUVORK5CYII=");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  i {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 18px;
  }
}

a.text-primary {
  margin-left: 6px;
  cursor: pointer;
  color: #eee !important;
}
.message {
  font-size: 10px;
  color: #949393;
}
.search {
  box-shadow: none;
  height: 38px;
  border-left: none;
}

.jobLink {
  background: #fbf1c0;
  border-radius: 4px;
  padding: 1px;
  margin-left: 3px;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.message {
  p {
    margin: 0px;
  }
}

.unread {
  background-color: #a9a9a9;
  padding: 0px 5px;
  font-size: 11px;
  font-weight: 500;
}
</style>

<style lang="scss">
.custom-email-filter {
  .vs__selected {
    color: white;
    opacity: 1;
    font-weight: bolder;
  }
  .vs__dropdown-toggle {
    height: 38px;
    border-radius: 0 !important;
    border-right: 0 !important;
  }
  .vs__dropdown-menu {
    width: 100%;
    min-width: 120px;
  }
}
</style>
